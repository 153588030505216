import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';
import * as signalR from "@microsoft/signalr";
import { nextTick } from "process";
import { BehaviorSubject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { LangsRepository } from "./langs.repository";
import { LeadClientRepositoryBUT } from "./lead.clients.repository.but";
import { NotificationService } from "./notification.service";
import { WhatsAppChatType } from "../Enums/whatsappChatType.enum";
import { ILang } from "../model/lang.interface";
import { LeadClientWhatsappReceivedMessage } from "../model/leadClients/lead.client.whatsapp.receivedmessage";
import { LeadClientWhatsappMessage } from "../model/leadClients/lead.client.whatsapp.message";
import { User } from "../model/user.model";

@Injectable()
export class WhatsappService {
    private isConnected = false;
    private signalRConnectionSubject = new BehaviorSubject<signalR.HubConnection>(null);
    public signalRConnection$ = this.signalRConnectionSubject.asObservable();
    public signalRConnection: signalR.HubConnection;
    public signalRConnectionSubscription: Subscription;
    private receivedMessageSubject = new BehaviorSubject<LeadClientWhatsappReceivedMessage>(null);
    public receivedMessage$ = this.receivedMessageSubject.asObservable();
    public receivedMessage: LeadClientWhatsappReceivedMessage;
    public receivedMessageSubscription: Subscription;
    private signalRConnectionCloseRequested = false;
    private token = "";
    private chatType = WhatsAppChatType.Client;

    constructor(
        protected langsRepository: LangsRepository,
        protected leadClientRepository: LeadClientRepositoryBUT,
        public readonly notificationService: NotificationService,
        private platform: Platform
    ) { }

    get currentLang(): ILang { return this.langsRepository.current };

    setSignalRConnection(signalRConnection: signalR.HubConnection) {
        this.signalRConnectionSubject.next(signalRConnection);
    }

    setReceivedMessage(receivedMessage: LeadClientWhatsappReceivedMessage) {
        this.receivedMessageSubject.next(receivedMessage);
    }

    public setToken(token: string): void {
        this.token = token;
    }

    public setWhatsAppChatType(chatType: WhatsAppChatType): void {
        this.chatType = chatType;
    }

    public async startConnection(userEmail: string) {
        try {
            console.log('startConnection', userEmail);
            this.signalRConnectionCloseRequested = false;
            this.signalRConnection.start().then(async () => {
                if (this.signalRConnection) {
                    await this.signalRConnection.invoke('ConnectUser', userEmail);
                    this.isConnected = true;
                }
            }, err => {
                console.error('start signalRConnection rejected', err)
            }).catch(err => {
                console.error('start signalRConnection error', err)
            });
        } catch (err) {
            console.error('startConnection error', err);
            setTimeout(() => {
                this.startConnection(userEmail);
            }, 5000);
        }
    };

    public async subscribe(user: User): Promise<void> {
        await this.platform.ready();

        this.signalRConnectionSubscription = this.signalRConnection$.subscribe(async (con: signalR.HubConnection) => {
            if (con !== null && !this.signalRConnection) {
                this.signalRConnection = con;
                await this.startConnection(user.email);
            }
        });

        let connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.baseFixUrl}chatHub`, { transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();
        connection.onclose(async (error?: Error) => {
            if (!this.signalRConnectionCloseRequested) {
                await this.startConnection(user.email);
            }
            if (error) {
                console.error("error in connection.onclose", error);
            }
        });
        this.setSignalRConnection(connection);
    }

    public unsubscribe(user: User): void {
        if (this.signalRConnection) {
            if (this.signalRConnection.state == signalR.HubConnectionState.Connected) {
                this.signalRConnection.invoke('DisconnectUser', user.email)
                    .finally(() => {
                        if (this.signalRConnection) {
                            this.signalRConnectionCloseRequested = true;
                            if (this.isConnected) {
                                this.signalRConnection.stop().then(_ => {
                                    this.signalRConnection = null;
                                    this.setSignalRConnection(null);
                                    this.isConnected = false;
                                });
                            }
                        }
                    });
            } else {
                this.signalRConnection = null;
                this.setSignalRConnection(null);
            }

            if (this.signalRConnectionSubscription) {
                this.signalRConnectionSubscription.unsubscribe();
            }
            this.setReceivedMessage(null);
            this.receivedMessage = null;
            if (this.receivedMessageSubscription) {
                this.receivedMessageSubscription.unsubscribe();
            }
        }
    }

    public formatWhatsappMessage(msg: LeadClientWhatsappMessage) {
		if (!msg.message) {
			return "";
		}
		msg.message = msg.message.replace(/\n/g, "<br />");

		const urlRegex = /\b((https?:\/\/\S+(?:png|jpe?g|gif)\S*)|(https?:\/\/api\-eu\.nexmo\.com\/v3\/media\/\S+))\b/gi;
		const fileRegex = /\b(leadClient\/getMessageFile\/(\w+)\/(.+\.\w+))\b/gi;
        const fileNameRegex = /[^\/]+$/;

		let datePast = new Date();
		const createDate = new Date(msg.createDate);
		if (urlRegex.test(msg.message)) {
			datePast.setDate(datePast.getDate() - 3);
			if (createDate <= datePast) {
				return msg.message.replace(urlRegex, `<b>${this.currentLang.words["image-deleted"]}</b>`);
			}
			return msg.message
                // replace uploaded images to alias with https (instead of http)
                .replace("http://prod2-clp-fixdigital.s3-website-eu-west-1.amazonaws.com", "https://lpc.fixdigital.co.il")
                .replace(urlRegex, `<a href='$1' target='_blank'><img src='$1' alt='image' /></a>`);
		}
		if (fileRegex.test(msg.message)) {
			datePast.setDate(datePast.getDate() - 90);

			if (createDate <= datePast || msg.message.includes('/deleted/')) {
				return msg.message.replace(fileRegex, `<b>${this.currentLang.words["image-deleted"]}</b>`);
			}
			if (!msg.fileUrl) {
				let url = msg.message.match(fileRegex)[0];
                var getMessageFileMethodPromise = this.chatType === WhatsAppChatType.Client
                    ? this.leadClientRepository.getMessageFile(url)
                    : this.leadClientRepository.getMessageFileWithCrmToken(this.token, url);
                getMessageFileMethodPromise.then(blob => {
						msg.file = blob;
						let fileUrl = window.URL.createObjectURL(blob);
						msg.fileUrl = fileUrl;
					})
					.catch(reason => {
						console.warn(reason);
						msg.fileUrl = null;
						msg.message = `<b>${this.currentLang.words["message-error"]}</b>`
					});

				msg.fileUrl = '/loading.svg';
			}

			if (msg.messageContentType == "video") {
				return msg.message.replace(fileRegex, `<video controls><source src='${msg.fileUrl}'> </video>`);
			}

			if (msg.messageContentType == "audio") {
				return msg.message.replace(fileRegex, `<audio controls><source src='${msg.fileUrl}'> </audio>`);
			}

			if (msg.messageContentType == "file") {
				fileRegex.lastIndex = 0;
				const match = fileRegex.exec(msg.message);
				const matchFilename = fileNameRegex.exec(match[0]);
                const filename = match ? matchFilename[0] : "unknown";

				return msg.message.replace(fileRegex, this.createFileMessageHtml(filename, msg.fileUrl));
			}

			nextTick(() => {
				msg.imgSrc = msg.fileUrl;
			});
			return msg.message.replace(fileRegex, "");
		}

		if (msg.adPreview) {
			return `${msg.message} ${msg.adPreview}`;
		}

		return msg.message;
	}

    private createFileMessageHtml(filename: string, fileUrl: string): string {
		return "<div class='file'>" +
			"<span class='file-icon'></span>" +
			"<div class='meta'>" +
			`<div class='title'>${filename}</div>` +
			`<a target="_blank" href='${fileUrl}' class="download" download=${filename}>Download</a>` +
			"</div>" +
			"</div>"
	}
}
